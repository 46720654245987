/* Import Tailwind's base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom body styles */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5; /* Light background */
  color: #333; /* Default text color */
  line-height: 1.6; /* Improve readability */
}

/* Tailwind's default typography with custom code block styles */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: #f7f7f7; /* Light background for code */
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.9em; /* Slightly smaller text */
}

/* Optionally, you can also use Tailwind's prose class for better code block styling */
.prose code {
  background-color: #f7f7f7;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.9em;
}

/* Optional: Prose class styling for better typography */
.prose {
  max-width: 65ch; /* Control line length */
  margin: auto;
  color: #333;
  line-height: 1.75;
}


/* Custom animation for marquee */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Apply animation to scrolling text */
.animate-marquee {
  animation: marquee 10s linear infinite;
}

/* Hide marquee animation on mobile devices */
@media (max-width: 1023px) {
  .animate-marquee {
    animation: none; /* Disable the animation on screens smaller than 1024px */
  }
}


.react-image-magnify__container {
  position: relative; /* Prevents unnecessary shifting */
  overflow: hidden;
}

/* Fix layout shift by setting minimum dimensions */
.page-wrapper {
  min-height: 100vh; /* Ensure the page takes at least full height */
}

.swiper-slide {
  transition: transform 0.3s ease-in-out; /* Smooth the transition */
}

.swiper-thumb {
  padding: 5px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
}
.swiper-thumb:hover {
  border-color: #ddd;
}



