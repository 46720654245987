
.swiper {
  width: 100%;  
  height: 40vh; 
  position: relative; 
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  
  display: flex;
  justify-content: center;  
  align-items: center;     
  height: 100%;            
}


@media (max-width: 768px) {
  .swiper-slide {
    font-size: 16px;  
  }

  .swiper {
    height: 40vh;  
  }
}

@media (max-width: 480px) {
  .swiper-slide {
    font-size: 14px; 
  }

  .swiper {
    height: 35vh; 
  }
}
